<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 80 91"
  >
    <path
      fill="currentColor"
      fill-rule="evenodd"
      d="M79.3 25.6V79A11.2 11.2 0 0 1 68 90.3v-5.6a5.6 5.6 0 0 0 5.6-5.7V25.6H62.4a8.4 8.4 0 0 1-8.5-8.4V5.9H23a5.6 5.6 0 0 0-5.6 5.7v50.6h-5.6V11.6A11.2 11.2 0 0 1 23 .3h31l25.3 25.3ZM51.5 80H49v-3.4h2.4c.6 0 1.3-.1 1.8-.4.5-.3 1-.7 1.3-1.2.3-.4.4-1 .4-1.6a2.8 2.8 0 0 0-1-2.2c-.7-.5-1.6-.8-2.5-.8-.5 0-1 0-1.4.2l-1.1.7a2.8 2.8 0 0 0-1 2h-4.1c0-1 .2-1.8.5-2.5.4-.8.9-1.5 1.6-2 .6-.6 1.4-1 2.4-1.3 1-.4 2-.5 3.2-.5 1.6 0 3 .2 4.1.7a7 7 0 0 1 2.7 2c.6 1 1 2 1 3.1a5 5 0 0 1-.7 2.7 5.4 5.4 0 0 1-3.5 2.5v.2a5.7 5.7 0 0 1 4 2.5c.6.8.9 1.8.8 2.9 0 1-.2 2-.6 2.8-.5.9-1.1 1.6-1.9 2.2a9 9 0 0 1-2.7 1.3 11.7 11.7 0 0 1-7.6-.4c-1.1-.5-2-1.3-2.8-2.3-.6-1-1-2-1-3.2h4.1c0 .5.3 1 .6 1.4.4.5.9.8 1.4 1a5.5 5.5 0 0 0 4.2 0 4 4 0 0 0 1.4-1.2 4 4 0 0 0 .5-1.8 3.1 3.1 0 0 0-1.9-3c-.6-.3-1.3-.4-2-.4Zm-47 9.9v-15h.2L10 87.1h3l5.3-12.2h.2v15h4V67.4H18L11.6 82h-.2L5 67.4H.5v22.5h4Zm30.7-22.5h-9v22.5h4.4v-7.6h4.5a8 8 0 0 0 4.2-1c1.1-.6 2-1.5 2.6-2.6a8 8 0 0 0 .9-3.8 7 7 0 0 0-1-3.8c-.5-1.1-1.4-2-2.5-2.7-1.3-.7-2.7-1-4.1-1Zm3 7.5a5 5 0 0 1-.4 2.1c-.3.6-.8 1-1.4 1.4-.6.3-1.4.5-2 .4h-3.8V71h3.7a4 4 0 0 1 2.9 1c.7.7 1 1.7 1 3Z"
      clip-rule="evenodd"
    />
  </svg>
</template>
